<template>
  <section class="header">
    <HeaderAds />
    <div class="top-header">
      <img :src="require(`@/assets/${dynamicLogo()}`)" alt="HCM Logo" />
      <div
        class="flex justify-center items-center flex-col"
        alt="Selecionar Menus"
      >
        <b-dropdown
          :disabled="isDropdownActive"
          :scrollable="isScrollable"
          :max-height="maxHeight"
          v-model="currentMenu"
          aria-role="list"
        >
          <template #trigger>
            <b-button
              :label="currentMenu.section"
              class="dropdown-style"
              icon-right="menu-down"
            />
          </template>

          <b-dropdown-item
            v-for="(menu, index) in filteredMenus"
            :key="index"
            :value="menu"
            @click="activeTab = getOriginalIndex(menu)"
            aria-role="listitem"
          >
            <div class="media">
              <div class="media-content">
                <h3>{{ menu.section }}</h3>
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="items-selector">
      <ItemsScroll :currentMenu="currentMenu" />
    </div>
  </section>
</template>

<script>
import ItemsScroll from "../fragments/ItemsScroll.vue";
import HeaderAds from "./HeaderAds.vue";

export default {
  name: "pageHeader",
  props: {
    menus: {
      type: Array,
      required: true,
    },
  },
  components: {
    ItemsScroll,
    HeaderAds,
  },
  data() {
    return {
      isScrollable: true,
      maxHeight: 400,
      currentMenu: null,
      activeTab: 0,
    };
  },
  watch: {
    activeTab(value) {
      console.log("Header watch", value);
      this.updateUrl(value);
      this.$emit("update:activeTab", value);
    },
  },
  computed: {
    isDropdownActive() {
      return this.currentMenu.isDropdownDisabled;
    },
    filteredMenus() {
      return this.menus.filter((menu) => !menu.isDropdownDisabled);
    },
  },
  created() {
    this.updateActiveTabFromQuery();
    this.currentMenu = this.menus[this.activeTab];
  },
  methods: {
    updateUrl(id) {
      if (this.$route.query.id !== id.toString()) {
        this.$router.replace({ query: { id: id.toString() } });
      }
    },
    updateActiveTabFromQuery() {
      const urlParams = new URLSearchParams(window.location.search);
      const idFromUrl = urlParams.get("id");
      const menuLength = this.menus.length;
      if (idFromUrl && idFromUrl < menuLength) {
        this.activeTab = parseInt(idFromUrl);
      }
      if (idFromUrl && (idFromUrl >= menuLength || idFromUrl < 0)) {
        this.activeTab = 0;
      }
      this.currentMenu = this.menus[this.activeTab];
      this.updateUrl(this.activeTab);
    },
    dynamicLogo() {
      const urls = {
        0: "MEROlogo.png",
        3: "UCAlogo.png",
        10: "MEROlogo.png",
      };

      return urls[this.activeTab] ?? "hcmLogo.png";
    },
    getOriginalIndex(customMenu) {
      return this.menus.findIndex(
        (menu) => menu.section === customMenu.section
      );
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #544b45;
  height: 100px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 49;
}

.top-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
  min-height: 70px;
  max-height: 70px;
}

.top-header img {
  max-height: 70px;
  max-width: 110px;
  width: auto;
  /**margin: auto;**/
  /**filter: invert(1) brightness(1) contrast(0);**/
  filter: brightness(0) invert(1) saturate(0);
}

.dropdown-style {
  background-color: #554b45;
  color: white;
  border: 0;
  text-transform: uppercase;
  transition: 0.44s ease-in-out;

  &:hover {
    background-color: #483f3a;
    color: #fff;
  }

  /** &:active {
    background-color: #483f3a;
    color: #fff;
  } **/
}

.items-selector {
  background-color: #483f3a;
  color: #fdfdfd;
  padding-left: 10px;
  min-width: 100vw;
}

/* Efeito de clique */
.button:active {
  transform: scale(0.8);
  background-color: #483f3a;
  color: #fdfdfd;
}

.button:focus,
.button.is-focused {
  border-color: #5c504a !important;
  color: #fdfdfd;
}

.button:focus:not(:active),
.button.is-focused:not(:active) {
  -webkit-box-shadow: 0 0 0 0.125em rgb(80 60 53);
  box-shadow: 0 0 0 0.125em rgb(80 60 53);
}
a.dropdown-item.is-active,
.dropdown .dropdown-menu .has-link a.is-active,
button.dropdown-item.is-active {
  background-color: #554b45;
  color: #fff;
}
</style>
